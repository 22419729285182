
import React from 'react'

export default function TopBanner(props) {
    return (
        <div className='row top-banner g-0 justify-content-between position-relative' style={{ backgroundImage: `url(${props.TopBannerBg})` }}>
            <div className="row justify-content-center align-items-center h-100">
                <h1 className='text-center'>{props.topBannerTitle}</h1>
            </div>
            <div className="col-12 position-absolute bottom-0">
                <div className='w-100 d-flex align-items-end'>
                    <img src={props.topBannerBottom} alt="" className='w-100' />
                </div>
            </div>

        </div>
    )
}
