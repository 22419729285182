import React, { useEffect, useState } from 'react'
import TopBanner from './TopBanner'
import Banner from './Banner'
import { axiosBase } from '../Rootlayout';
import { Link } from 'react-router-dom';
import Masonry from "react-responsive-masonry"

export default function Gallery() {
    const [galleries, setGalleries] = useState([]);
    const [imagegalleries, setImageGalleries] = useState([]);
    const data = [
        {
            "cover": "https://picsum.photos/200/300.jpg"
        },
        {
            "cover": "https://picsum.photos/400/300.jpg"
        },
        {
            "cover": "https://picsum.photos/200/300.jpg"
        },
        {
            "cover": "https://picsum.photos/200/400.jpg"
        },
        {
            "cover": "https://picsum.photos/200/300.jpg"
        },
    ];
    useEffect(() => {
        axiosBase.get(`imagegalleries`)
            .then(response => response.data)
            .then((response) => setImageGalleries(response.data))
            .catch(err => {
                console.log(err.message);
            });
    }, []);
    useEffect(() => {
        axiosBase.get(`galleries`)
            .then(response => response.data)
            .then((response) => setGalleries(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);


    return (
        <div className="container-fluid gallery g-0">
            <TopBanner topBannerTitle={galleries.gallery} topBannerBottom={galleries.banner_bottom_img} TopBannerBg={JSON.stringify(galleries.gallery_img)} />
            {/* <div className="row  g-0 p-3 p-md-5">
                <div className="col-12 px-0">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-md-3 g-4">
                            {imagegalleries?.map((item, index) => (
                                <div className="col" key={index}>
                                    // comment two lines below
                                    {localStorage.setItem('galleriesIdFromLocalStorage', item.id)}
                                    {localStorage.setItem('galleriesAllFromLocalStorage', JSON.stringify(item))}
                                    <div className="mason-item rounded-5">
                                        <img src={item.coverImages} className="card-img-top rounded-5" alt="..." />
                                        <Link to={`/imagegalleries/${item.id}`} className=" border-0 position-absolute h-100 w-100 p-0 rounded-5 gallery-item-inner text-decoration-none">
                                            <div className="d-flex justify-content-center align-items-center h-100 rounded-5" style={{ background: 'rgb(0 0 0 / 40%)' }}>
                                                <span className='text-white text-decoration-none'>{item.title}</span>
                                            </div>
                                        </Link>
                                        //comment whole link tag
                                        <Link to={`${url}imagegalleries/${item.id}`} className="card-footer border-0 position-absolute h-100 w-100 p-0 rounded-5 gallery-item-inner">
                                            <div className="d-flex justify-content-center align-items-center h-100 rounded-5" style={{ background: 'rgb(0 0 0 / 40%)' }}>
                                                <span className='text-white'>{item.title}</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container">
                <div className="row p-3 p-md-5">
                    <Masonry columnsCount={3} gutter="10px">
                        {imagegalleries?.map((item, index) => (

                            <div key={index}>
                                <div className="mason-item rounded-5 position-relative">
                                    <img
                                        src={item.coverImages}
                                        style={{ width: "100%", display: "block", borderRadius: '15px' }}
                                    />
                                    <Link to={`/imagegalleries/${item.id}`} className=" border-0 h-100 w-100 p-0 rounded-5 gallery-item-inner text-decoration-none">
                                        <div className="d-flex justify-content-center align-items-center h-100 rounded-5" style={{ background: 'rgb(0 0 0 / 40%)' }}>
                                            <span className='text-white text-decoration-none'>{item.title}</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        ))}
                    </Masonry>
                </div>
            </div>


            <Banner BannerLink="#" BannerBg="/assets/img/banner-bg-white.png" />
        </div >
    )
}
