import React from 'react'

export default function Spinner() {
    return (
        <>
            <div className="position-relative" >
                <div className="row w-100 p-5 justify-content-center align-items-md-center position-absolute top-0 " style={{ height: '100vh', zIndex: '10', background: 'rgb(255 255 255 / 95%)', left: '12px' }}>
                    <div className="text-center p-5">
                        <div className="spinner-border text-black p-5" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}