import React, { useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Footer from './components/Footer';
import axios from "axios";
import { SherpaContext } from './context/notes/Sherpacontext';

// creating a axios component
// export const url = 'http://localhost:3000/'
// export const url = 'http://yakland.clients.neolinx.com/'
export const axiosBase = axios.create({
    baseURL: 'https://admin-yakland.clients.neolinx.com/api/'
});
// export const recaptchaSiteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';// use for testing
export const recaptchaSiteKey = '6LdrTyEpAAAAAFhcrFcxH7647n1x0ed1g0wcBpSs';


export default function Rootlayout() {
    const [setting, setSetting] = useState([]);
    const [banner, setBanner] = useState([]);
    const [countryData, setCountryData] = useState('');
    // const [teams, setTeams] = useState([]);
    // const [abouts, setAbouts] = useState([]);
    useEffect(() => {
        axiosBase.get(`setting`)
            .then(response => response.data)
            .then((response) => setSetting(response.data))
            .catch(err => {
                console.log(err.message);
            })
        axiosBase.get(`banner`)
            .then(response => response.data)
            .then((response) => setBanner(response.data))
            .catch(err => {
                console.log(err.message);
            });

    }, []);

    return (
        <>
            <SherpaContext.Provider value={{ setting, setSetting, banner, setBanner, countryData, setCountryData }}>
                <Navbar />
                <Outlet />
                <Footer />
            </SherpaContext.Provider>
        </>
    )
}