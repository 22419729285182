import React, { useContext, useEffect, useState } from 'react'
import { axiosBase } from '../Rootlayout';
import { SherpaContext } from '../context/notes/Sherpacontext';
import TopBanner from './TopBanner';
import { Parser } from 'html-to-react';
import AboutTeamItem from './AboutTeamItem';

export default function Guides() {
    const { banner } = useContext(SherpaContext);
    const [guides, setGuides] = useState([]);
    const [guideTeams, setGuideTeams] = useState([]);
    useEffect(() => {
        //for cookies
        axiosBase.get(`/page/guides`)
            .then(response => response.data)
            .then((response) => setGuides(response.data))
            .catch(err => {
                console.log(err.message);
            });
        axiosBase.get(`guides`)
            .then(response => response.data)
            .then((response) => setGuideTeams(response.data))
            .catch(err => {
                console.log(err.message);
            });

    }, []);
    return (
        <div>
            {guides.image != null ? <TopBanner topBannerTitle={guides.title} topBannerBottom={guides.image} TopBannerBg={JSON.stringify(banner.contact_img)} /> : <TopBanner topBannerTitle={guides.title} topBannerBottom={banner.banner_bottom_img} TopBannerBg={JSON.stringify(banner.contact_img)} />}
            <div className="container">
                {guides.description ? <div className="row p-3 p-lg-5">
                    <div className="col-12">
                        {Parser().parse(guides.description)}
                    </div>
                </div> : ''}

                <div className="row row p-3 px-lg-5 about">
                    <div className="col-12 about-team">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 g-0 py-3 py-md-5 justify-content-center">
                            <h1 className="text-uppercase text-center text-nowrap w-100">meet the Guides</h1>
                            {guideTeams?.map((item, index) => (
                                <AboutTeamItem key={index} id={index} teamImg={item.img} teamPosition={item.postion} teamName={item.name} teamDesc={item.description} />
                            ))}

                        </div>
                    </div>
                </div>
                {/* <div className="row p-3 p-md-5">
                    <div className="card mb-3 px-0">
                        <div className="row g-0">
                            <div className="col-md-5">
                                <img src="./assets/img/user.png" className="img-fluid rounded-start" alt="..." style={{ minHeight: '300px' }} />
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title">Annapurna Trek</h5>
                                    <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3 px-0">
                        <div className="row g-0">
                            <div className="col-md-5">
                                <img src="./assets/img/user.png" className="img-fluid rounded-start" alt="..." style={{ minHeight: '300px' }} />
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title">Everest Trek</h5>
                                    <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3 px-0">
                        <div className="row g-0">
                            <div className="col-md-5">
                                <img src="./assets/img/user.png" className="img-fluid rounded-start" alt="..." style={{ minHeight: '300px' }} />
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title">Langtang Trek</h5>
                                    <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3 px-0">
                        <div className="row g-0">
                            <div className="col-md-5">
                                <img src="./assets/img/user.png" className="img-fluid rounded-start" alt="..." style={{ minHeight: '300px' }} />
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title">Chure Trek</h5>
                                    <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
