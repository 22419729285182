import React, { useEffect, useState } from 'react'
import TopBanner from './TopBanner'
import PackageItem from './PackageItem'
import Banner from './Banner'
// import { SherpaContext } from '../context/notes/Sherpacontext';
import { axiosBase } from '../Rootlayout';

export default function PeakClimbing() {
    // const { trekking, setTrekking } = useContext(SherpaContext);
    const [trekking, setTrekking] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const Data = trekking.trekkings;
    const recordPerPage = 9;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = Data?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(Data?.length / recordPerPage);
    var numbers = [];
    for (var i = 1; i <= npage; i++) {
        numbers.push(i);
    }
    useEffect(() => {
        axiosBase.get(`trekking`)
            .then(response => response.data)
            .then((response) => setTrekking(response.data))
            .catch(err => {
                console.log(err.message);
            })
    }, []);
    return (
        <div className="container-fluid g-0">
            <TopBanner topBannerTitle={trekking.trekking} topBannerBottom={trekking.banner_bottom_colour_img} TopBannerBg={JSON.stringify(trekking.banner_img)} />
            <div className="row g-0 packages p-5">
                {records?.map((item, index) => (
                    <div className="col-12 col-sm-6 col-lg-4" key={index}>
                        <PackageItem packageName={item.title} packageItemImg={item.featured_img} iconLeftText={item.max_elevation} iconRIghtText={item.trip_length} btnText="find out more" btnLink={`/details/${item.slug}`} />
                    </div>

                ))}

                <nav aria-label='Page navigation example' className={`${npage > 1 ? '' : 'd-none'}`}>
                    <ul className='pagination justify-content-center'>
                        <li className='page-item'>
                            <button
                                className={`page-link ${currentPage === 1 ? "disabled" : ""}`}
                                onClick={prePage}
                            >
                                Previous
                            </button>
                        </li>
                        {numbers.map((n, i) => (
                            <li
                                className={`page-item ${currentPage === n ? "active" : ""}`}
                                key={i}
                            >
                                <button className='page-link' onClick={() => changeCPage(n)}>
                                    {n}
                                </button>
                            </li>
                        ))}
                        <li className='page-item'>
                            <button
                                className={`page-link ${currentPage === npage ? "disabled" : ""}`}
                                onClick={nextPage}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            <Banner BannerLink="#" BannerBg="/assets/img/banner-bg-trek.png" />
        </div>
    )
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1);
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
}


// import React from 'react'
// import PackageItem from './PackageItem'
// import TopBanner from './TopBanner'
// import Banner from './Banner'

// export default function Trekking() {
//     return (
//         <>  <div className="container-fluid px-0">
//             <TopBanner topBannerTitle="Trekking" topBannerBottom="assets/img/top-banner-bottom-bg.png" TopBannerBg="/assets/img/top-banner.jpg" />
//             <div className='row g-0 t-packages'>
//                 <div className="col-12 col-sm-6 col-lg-4">
//                     <PackageItem packageName="langtang trek" packageItemImg="assets/img/packages-item1.png" iconLeftText="1400m" iconRIghtText="12 days" btnText="find out more" btnLink="/findmore" />
//                 </div>
//                 <div className="col-12 col-sm-6 col-lg-4">
//                     <PackageItem packageName="annapurna base camp" packageItemImg="assets/img/packages-item2.png" iconLeftText="1400m" iconRIghtText="12 days" btnText="find out more" btnLink="/findmore" />
//                 </div>
//                 <div className="col-12 col-sm-6 col-lg-4">
//                     <PackageItem packageName="everest base camp" packageItemImg="assets/img/packages-item3.png" iconLeftText="1400m" iconRIghtText="12 days" btnText="find out more" btnLink="/findmore" />
//                 </div>
//                 <nav aria-label="listing pagination">
//                     <ul className="pagination justify-content-center">
//                         <li className="page-item disabled">
//                             <a className="page-link">Previous</a>
//                         </li>
//                         <li className="page-item active" aria-current="page">
//                             <a className="page-link" href="#">1</a>
//                         </li>
//                         <li className="page-item disabled">
//                             <a className="page-link" href="#">2</a>
//                         </li>
//                         <li className="page-item disabled">
//                             <a className="page-link" href="#">Next</a>
//                         </li>
//                     </ul>
//                 </nav>
//             </div>
//             <Banner BannerLink="#" BannerBg="/assets/img/banner-bg-trek.png" />
//         </div>
//         </>
//     )
// }
