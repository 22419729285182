// import { Parser } from 'html-to-react'
import React, { useEffect, useState } from 'react'
import { axiosBase } from '../Rootlayout';
import { Link } from 'react-router-dom';
// import { url } from '../Rootlayout'

export default function Why(props) {

    const [whyList, setWhyList] = useState([]);
    useEffect(() => {
        //for why
        axiosBase.get(`whylists`)
            .then(response => response.data)
            .then((response) => setWhyList(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);
    return (
        <div className="why">
            <div className='why-overlay-top'>
                <div className="d-flex">
                    <img src="assets/img/why-over.png" alt="" />
                </div>
            </div>
            <div className="container">
                <div className="row flex-lg-row-reverse">
                    <div className="col-12 col-lg-6 why-img">
                        <div className="why-img-inner">
                            <img src={props.whyImg} alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 why-text">
                        <h1 className="title text-uppercase">why sherpa<br></br> yak land?</h1>
                        <ul>
                            {whyList.map((whyList) =>
                                <li key={whyList.id}>
                                    {whyList.list}
                                </li>
                            )}
                        </ul>
                        <Link to={props.whyMoreLink} className="btn button-primary text-uppercase w-auto">more reasons?</Link>
                        {/* <button onClick={() => window.location.href(`${url}${props.whyMoreLink} `)} className="btn button-primary-outline text-uppercase text-white w-auto">more reasons?</button> */}
                        {/* {Parser().parse(props.whyContent)} */}
                        {/* {props.whyContent} */}
                    </div>
                </div>
            </div>
            <div className='why-overlay-bottom'><div className="d-flex"><img src="assets/img/why-over.png" alt="" /></div></div>
        </div>
    )
}
