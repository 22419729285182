import React from 'react'
import { Link } from 'react-router-dom'

export default function PackageItem(props) {
    return (
        <div className="packages-item">
            <div className='d-flex justify-content-center packages-item-inner'>
                <img src={props.packageItemImg} alt="" />
                <div className="packages-item-inner-overlay d-flex justify-content-center align-items-end p-3 p-xl-4">
                    <div>
                        <h6 className="text-uppercase text-center">{props.packageName}</h6>
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '30px' }}>
                            <div className='d-flex icon align-item justify-content-center'>
                                <img src="assets/img/mountain.png" alt="" />
                                <span>{props.iconLeftText}</span>
                            </div>
                            <div className='d-flex icon align-item justify-content-center'>
                                <img src="assets/img/clock.png" alt="" />
                                <span className='text-uppercase text-nowrap'>{props.iconRIghtText}</span>
                            </div>
                            <Link to={props.btnLink} className="btn button-primary text-uppercase">{props.btnText}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
