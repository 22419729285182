import React, { useContext, useEffect, useState } from 'react'
import { axiosBase } from '../Rootlayout';
import { SherpaContext } from '../context/notes/Sherpacontext';
import TopBanner from './TopBanner';
import { Parser } from 'html-to-react';

export default function Support() {
    const { banner } = useContext(SherpaContext);
    const [support, setSupport] = useState([]);
    useEffect(() => {
        //for cookies
        axiosBase.get(`/page/supports`)
            .then(response => response.data)
            .then((response) => setSupport(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);
    return (
        <div>
            <TopBanner topBannerTitle={support.title} topBannerBottom={banner.banner_bottom_img} TopBannerBg={JSON.stringify(banner.contact_img)} />
            <div className="container">
                <div className="row p-3 p-lg-5">
                    <div className="col-12">
                        {Parser().parse(support.description)}
                    </div>
                    {/* <div className="col-12">
                        <h2><strong>Support</strong></h2>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ea molestiae minus adipisci velit officia voluptate, dignissimos ipsa. Deleniti perferendis quasi maiores dolores reiciendis corporis ipsa ratione aspernatur, fuga sint porro quisquam esse vitae libero, aperiam labore recusandae! Maiores, obcaecati, ab earum quo voluptates vero atque autem vel sed necessitatibus illum?</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum perspiciatis harum nam animi aliquam beatae, fuga est sint quidem omnis optio et mollitia autem atque dolores sed repellat molestiae maxime commodi. Unde maxime quibusdam provident autem architecto minus quam! Illum vel similique provident tempora molestiae ratione eos facilis voluptates placeat!</p>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates consequatur corrupti repudiandae, explicabo dolorum inventore voluptatibus vitae. Odio, dolor quo, totam, laborum perspiciatis dolores harum sapiente consectetur molestias fuga iure.</p>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
