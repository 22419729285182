import React, { useEffect, useState } from 'react'
import TopBanner from './TopBanner'
import Banner from './Banner'
import AboutTeam from './AboutTeam'
import { axiosBase } from "../Rootlayout";
import { Parser } from 'html-to-react';

export default function About() {

    const [teams, setTeams] = useState([]);
    const [abouts, setAbouts] = useState([]);
    // const [error, setError] = useState('');
    // const location = useLocation();
    // const propsData = location.state;

    useEffect(() => {
        axiosBase.get(`teams`)
            .then(response => response.data)
            .then((response) => setTeams(response.data))
            .catch(err => {
                console.log(err.message);
            })
        axiosBase.get(`abouts`)
            .then(response => response.data)
            .then((response) => setAbouts(response.data))
            .catch(err => {
                console.log(err.message);
            });

    }, []);
    const curloc = window.location.href;
    var loc = curloc.includes("#why-more")
    var pot = curloc.includes("#potters")
    if (loc) {
        var element = document.getElementById("why-more");
    } else if (pot) {
        var element = document.getElementById("potters");
    } else {
        var element = null;
    }

    if (element !== null) {
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    } else {
        window.scroll(0, 0)
    }

    return (

        <div className='about'>
            <TopBanner topBannerTitle={abouts.about} topBannerBottom={abouts.banner_bottom_img} TopBannerBg={JSON.stringify(abouts.banner_img)} />
            <div className="container-fluid g-0">
                <div className="container">
                    <div className="row  g-0 p-3 p-md-5 pb-md-0">
                        <div className="col-12 col-lg-6 col-xl-5 about-left">
                            <h2 className="text-uppercase textcolor-secondary text-center">{abouts.heading}</h2>
                            <img src={abouts.big_img} alt="" />
                        </div>
                        <div className="col-12 col-lg-6 col-xl-7 about-right">
                            {Parser().parse(abouts.body)}
                            <div className="row about-right-below">
                                <div className="col-12 col-md-5 px-0 py-1">
                                    <img src={abouts.small_left_img} alt="" />
                                </div>
                                <div className="col-12 col-md-7 px-0 py-1">
                                    <img src={abouts.small_right_img} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="why-more pt-3 pt-lg-5" id="why-more">
                        <div className="row pt-5">
                            <div className="col-12 why-text p-3 px-lg-5">
                                <h1 className="title text-uppercase text-center">{abouts.why_heading}</h1>
                                {Parser().parse(abouts.why_content)}
                            </div>
                        </div>
                    </section>
                </div>
                <AboutTeam teamData={teams} />
            </div>
            <Banner BannerLink="#" BannerBg="/assets/img/banner-bg-white.png" />
        </div>
    )
}
