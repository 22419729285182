import React from "react";
import Slider from "react-slick";
import PackageItem from './PackageItem'


export default function Packages(props) {

    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: true,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    return (
        <div className="packages">
            {props.bannerTop !== null ? <>
                {/* <img src="assets/img/packages-top.png" alt="" className='w-100' /> */}
                <img src={props.bannerTop} alt="" className='w-100' />
            </> : ''}
            <div className="row g-0 pb-md-5 pb-3 mx-3">
                <p className='text-capitalize textcolor-primary text-center'>{props.packagesTop}</p>
                <h2 className="textcolor-secondary text-uppercase text-center fw-bolder">{props.packagesHeading}</h2>
                <div className="container">
                    <div className="row g-0 p-3">
                        <div className="col mb-5">
                            <Slider {...settings}>
                                {props.packagesData.map((item) => (
                                    <PackageItem key={item.title} packageName={item.title} packageItemImg={item.featured_img} iconLeftText={item.max_elevation} iconRIghtText={item.trip_length} btnText="find out more" btnLink={`/details/${item.slug}`} />
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
