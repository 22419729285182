import React, { useEffect, useState } from 'react'
import TestimonialSlider from './TestimonialSlider'
import { axiosBase } from "../Rootlayout";

export default function Testimonials(props) {
    const [testimonials, setTestimonials] = useState([]);
    // const [error, setError] = useState('');
    useEffect(() => {
        axiosBase.get(`testimonials`)
            .then(response => response.data)
            .then((response) => setTestimonials(response.data))
            .catch(err => {
                console.log(err.message);
            });

    }, []);

    return (
        <div className='row testimonials g-0'>
            {/* <img src="assets/img/packages-top.png" alt="" className='w-100' /> */}
            <img src="assets/img/main-inner-bottom-bg-green.png" alt="" className='w-100' />
            <div className="col">
                <div style={{ paddingTop: '5vw' }}>
                    <p className='text-capitalize text-center'>Client testimonials</p>
                    <h2 className="textcolor-secondary text-center">{props.testimonialTitle}</h2>
                </div>
                <TestimonialSlider testimonialsData={testimonials} />
            </div>
            {/* <img src="assets/img/testimonials-below.png" alt="" /> */}
        </div>
    )
}
