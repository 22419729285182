import React, { useContext, useState } from 'react'
import TopBanner from './TopBanner'
import GoogleMap from './GoogleMap'
import Banner from './Banner'
import { axiosBase, recaptchaSiteKey } from '../Rootlayout'
import { SherpaContext } from '../context/notes/Sherpacontext'
import ReCAPTCHA from 'react-google-recaptcha'

export default function Contactus() {
    const { setting, banner } = useContext(SherpaContext);
    const [name, createName] = useState('')
    const [email, createEmail] = useState('')
    const [phone, createPhone] = useState('')
    const [comments, createComments] = useState('')
    // const [ContactUsBanner, setContactUsBanner] = useState([]);

    const [verified, setVerified] = useState('');
    function onChange(value) {
        // console.log("Captcha value:", value);
        setVerified(true);
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const post = {
            name: name,
            email: email,
            phone: phone,
            comments: comments,
        }
        try {

            const res = await axiosBase.post('contacts', post)
            alert(res.data.message);

        } catch (e) {
            alert(e)
        }
    }
    return (
        <div className='contact-us g-0'>
            <TopBanner topBannerTitle={banner.contact} topBannerBottom={banner.banner_bottom_img} TopBannerBg={JSON.stringify(banner.contact_img)} />
            <div className="container">
                <div className="row p-3 p-md-5 flex-lg-row-reverse">
                    <div className="col-12 col-lg-6 col-xl-4 contact-us-right">
                        <div className='contact-us-right-inner'>
                            <h3>Contact Us</h3>
                            <form onSubmit={onSubmit}>
                                <div className="mb-3">
                                    <input type="name" className="form-control py-3" id="inputName" placeholder="Name" required onChange={(event) => { createName(event.target.value) }} value={name} />
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control py-3" id="inputEmail" placeholder="Email" required onChange={(event) => { createEmail(event.target.value) }} />
                                </div>
                                <div className="mb-3">
                                    <input type="tel" className="form-control py-3" id="inputPhone" placeholder="Phone" required onChange={(event) => { createPhone(event.target.value) }} />
                                </div>
                                <div className="mb-3">
                                    <textarea className="form-control py-3" placeholder="Leave a comment here" id="textArea" style={{ height: "10vw" }} required onChange={(event) => { createComments(event.target.value) }} ></textarea>
                                </div>
                                <div className='text-end'>
                                    <div style={{ transform: "scale(0.9)", transformOrigin: "0 0" }}>
                                        <ReCAPTCHA
                                            sitekey={recaptchaSiteKey}
                                            onChange={onChange}

                                        />
                                    </div>
                                    <button type="submit" className="btn button-primary-outline" disabled={!verified}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-8 contact-us-left">
                        <h4 className='text-capitalize textcolor-secondary'>get in touch</h4>
                        <p>{setting.get_in_touch}</p>
                        <div className='contact-us-left-below'>
                            <div className='d-flex align-items-center py-3'>
                                {/* <i className='bx bxs-envelope'></i> */}
                                <i className="bi bi-envelope"></i>
                                <p className='m-0 mx-3'>{setting.email}</p>
                            </div>
                            <div className='d-flex align-items-center py-3'>
                                {/* <i className='bx bxs-mobile'></i> */}
                                <i className="bi bi-phone"></i>
                                <p className='m-0 mx-3'>{setting.phone}</p>
                            </div>
                            <div className='d-flex align-items-center py-3'>
                                <i className="bi bi-geo-alt-fill"></i>
                                <p className='m-0 mx-3'>{setting.address}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
            <GoogleMap />
            <Banner BannerLink="#" BannerBg="/assets/img/banner-bg-white.png" />
        </div >
    )
}
