import React, { useState } from 'react'
import { axiosBase } from '../Rootlayout'

export default function Banner(props) {
    const [email, createEmail] = useState('')
    const onSubmit = async (e) => {
        e.preventDefault()
        const post = {
            email: email,
        }
        try {

            const res = await axiosBase.post('subscribes', post)
            alert(res.data.message);

        } catch (e) {
            alert(e)
        }
    }
    return (
        <div className="row g-0">
            <div className='banner col' style={{ backgroundImage: `url(${props.BannerBg})` }}>
                <div className="container h-100">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-6 text-uppercase text-white ">
                                    <h1 className='banner-text'>Get the latest<br></br> offer and updates</h1>
                                </div>
                                <div className="col-12 col-md-6">
                                    <form onSubmit={onSubmit}>
                                        <div className="row">
                                            <div className="col-12 col-sm-8 py-md-3">
                                                <input type="email" className="form-control" id="email" name='email' placeholder='ENTER EMAIL ADDRESS' aria-describedby="emailHelp" required onChange={(event) => { createEmail(event.target.value) }} />
                                            </div>
                                            <div className="col-12 col-sm-4 py-2 py-md-3">
                                                <button type="submit" className="btn button-primary text-uppercase">Subscribe</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
