import React, { useContext, useEffect, useState } from 'react'
import { axiosBase } from '../Rootlayout';
import { Parser } from 'html-to-react';
import { SherpaContext } from '../context/notes/Sherpacontext';
import TopBanner from './TopBanner';
// import { useParams } from 'react-router-dom';

export default function Cookies() {
    const { banner } = useContext(SherpaContext);
    const [cookies, setCookies] = useState([]);
    useEffect(() => {
        //for cookies
        axiosBase.get(`/page/cookies`)
            .then(response => response.data)
            .then((response) => setCookies(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);
    return (
        <div>
            <TopBanner topBannerTitle={cookies.title} topBannerBottom={banner.banner_bottom_img} TopBannerBg={JSON.stringify(banner.contact_img)} />
            <div className="container">
                <div className="row p-3 p-lg-5">
                    <div className="col-12">
                        {Parser().parse(cookies.description)}
                    </div>
                    {/* <div className="col-12">
                        <h2 className='text-center pb-5'><strong>Cookies</strong></h2>
                        
                        <p>For more information about how we may use personal data, please read our privacy policy or email dataprotection@theguardian.com.</p>

                        <h3><strong>1. Introduction</strong></h3>
                        <p>This is the cookie policy for Guardian News & Media Limited (“Guardian”), our sites such as theguardian.com, and our associated apps (“our sites”). Some of our other sites and services have their own cookie policies, which will be relevant to you when you are using those sites and services.</p>

                        <p>For more information about how we may use personal data, please read our privacy policy or email dataprotection@theguardian.com.</p>

                        <h3><strong>2. What is a cookie?</strong></h3>
                        <p>A cookie is a small file that contains letters and numbers that is downloaded on to your computer when you visit a website. It is sent to your browser and stored on your computer’s hard drive, tablet or mobile device. When you visit our sites it can allow us to recognise and remember you.</p>

                        <h4><strong>Technologies similar to cookies</strong></h4>

                        <p>Technologies that store or access information on a user’s device are similar to cookies and are also covered by this policy. These technologies may include:</p>
                        <ul>
                            <li>Device fingerprinting – using a set of information without relying on cookies in order to identify a particular device. For this purpose where you are resident in Australia, our partner Ipsos Iris may collect your IP address, monitor settings, type of browser and operating system to create a unique serial number which constitutes a digital “fingerprint”.</li>
                            <li>Local storage – storage of data in the local device’s cache, for example to load content quicker.</li>
                            <li>Pixels – a small pixel graphic which is used to track user behaviour, site conversions etc.</li>
                            <li>Scripts – small computer programs embedded within websites that give them extra functionality but may access user devices.</li>
                        </ul>




                        <p>In this policy we call all cookies and similar technologies “cookies”, for ease.</p>

                        <h3><strong>3. First-party and third -party cookies</strong></h3>
                        <p>There are different types of cookies:</p>


                        <ul>
                            <li>First-party cookies – cookies that are set by the Guardian when you use our site.</li>
                            <li>Third-party cookies – cookies that are set by other parties or organisations (other than the Guardian) when you use our site. Some Guardian web pages may also contain content from or link to other sites which may set their own cookies. If you share a link to a Guardian page, the service you share it on may set a cookie on your browser.</li>
                        </ul>
                        <h3><strong>4. How do we use cookies?</strong></h3>
                        <p>We use cookies in a range of ways to improve your experience on our sites, including:</p>

                        <ul>
                            <li>Keeping you signed in.</li>
                            <li>Understanding how you use our site, for instance how long you stay on a page or which article you read next.</li>
                            <li>Delivering content to you.</li>
                            <li>Showing you journalism that is relevant to you.</li>
                            <li>Showing you Guardian products and services that are relevant to you.</li>
                            <li>Delivering marketing and service messages to you on our site.</li>
                            <li>Working with partners to deliver relevant advertising to you.</li>
                            <li>Reminding you to complete online journeys (e.g. registration flow).</li>
                            <li>Processing payments and refunds.</li>
                            <li>Monitoring how users interact with pages on our site to identify and remedy interface issues.</li>
                            <li>In the next sections, we explain in more detail how cookies on our site may be useful, as well as when and where you can control them.</li>
                        </ul>

                        <h3><strong>5. What types of cookies do we use?</strong></h3>
                        <p>We use four types of cookies:</p>

                        <ul>
                            <li>Strictly Necessary &ndash; These cookies are essential to provide you with services you have requested, which means they cannot be switched off through the &ldquo;Privacy Settings&rdquo; link. For example, strictly necessary cookies make it possible for you to stay logged into your Guardian account and make comments. If you set your browser to block these cookies, then these functions and services will not work for you. In particular, we won&rsquo;t be able to save your preferences about cookies.</li>
                            <li>Performance &ndash; These cookies measure how often you visit our site and how you use them. We use this information to get a better sense of how you engage with our journalism and to improve our sites so that you have a better experience. For example, we collect information about which of our pages are most frequently visited, and by which types of users. We also use third-party cookies to help with performance.</li>
                            <li>Functionality &ndash; These cookies are used to recognise you and remember your preferences or settings when you return to our site so that we can provide you with a more personalised experience. For example, if you are based in the UK, we will remember this and make sure that you receive the UK homepage when you visit our site, rather than the US or Australia homepage. A mix of first-party and third-party cookies are used.</li>
                            <li>Advertising &ndash; These cookies are used to collect information about your visit to our site, including the content you have viewed, the links you have followed and information about your browser, device and your IP address. We have set out more details on this below.</li>
                        </ul>

                        <h3><strong>6. How long do cookies last?</strong></h3>
                        <ul>
                            <li>
                                <p>Session cookies &ndash; These cookies only last as long as your online session and expire when you close your browser (e.g. Chrome or Safari).</p>
                            </li>
                            <li>
                                <p>Persistent cookies &ndash; These cookies stay on your device after your browser has been closed and last for a time specified in the cookie (but not longer than 13 months). We use these cookies when we need to remember you for more than one browsing session, for instance to remember your preferences from one visit to the next.</p>
                            </li>
                        </ul>

                        <h3><strong>7. Why do we use cookies for advertising?</strong></h3>
                        <p>From the very first edition of the Manchester Guardian, published in 1821, our journalism has been funded in part by advertising. Our editorial content is not influenced by the advertising we display and our journalists are free to, and often do, challenge the activities of companies and organisations that advertise and sponsor content that appears in Guardian sites and publications.</p>

                        <p>Increasingly, our readers fund us directly – either by buying a newspaper, or taking out a print or digital subscription or through making one-off, regular or monthly contributions. But to grow our number of supporters, we are reliant on marketing and still rely in part on advertising to fund our journalism. Therefore, cookies provide vital services to the Guardian without sacrificing our independence or values.</p>

                        <h3><strong>8. How do we use cookies for advertising?</strong></h3>
                        <p>If you accept cookies, as you browse our site, we will place cookies on your device. Some cookies are for advertising, so we can understand what sorts of pages you read and are interested in viewing. We can then display advertising on your browser based on these interests. For instance, if you have been reading a lot of food articles, you may be shown more adverts for food.</p>

                        <p>Our apps integrate third-party software that provides us with information about how the app is used and what content you have viewed for the purposes of online advertising and analytics. It uses cookies from some of the providers listed in our “Privacy Settings”, that can be found in the footer of every page on our site. The advertising techniques we use do not collect personal data such as your name, email address, postal address or phone number. We sometimes use information such as your IP address and browser type, and also sometimes share these with others for advertising purposes.</p>

                        <p>We also allow other organisations to use cookies and other technologies to help us analyse how our site is being used, measure the number of visitors to the site, and display advertising.</p>


                    </div> */}
                </div>
            </div>
        </div>
    )
}
