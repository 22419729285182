import React, { useContext, useEffect, useState } from 'react'
import { axiosBase } from '../Rootlayout';
import { SherpaContext } from '../context/notes/Sherpacontext';
import TopBanner from './TopBanner';
import { Parser } from 'html-to-react';


export default function Faq() {
    const { banner } = useContext(SherpaContext);
    const [faq, setFaq] = useState([]);

    useEffect(() => {
        //for faq
        axiosBase.get(`/faqs`)
            .then(response => response.data)
            .then((response) => setFaq(response))
            .catch(err => {
                console.log(err.message);
            })

    }, []);
    return (
        <div>
            <div>
                <TopBanner topBannerTitle='FAQs' topBannerBottom={banner.banner_bottom_img} TopBannerBg={JSON.stringify(banner.contact_img)} />
                <div className="container">
                    <div className="row faq p-3 p-md-5">
                        {faq.detail ? <div className="col-12 my-5 text-center">
                            <h2 className='textcolor-secondary'>{Parser().parse(faq?.detail.title)}</h2>
                            {Parser().parse(faq.detail.description)}
                        </div> : ''}
                        <div className="col-12">
                            <div className="accordion" id="accordionExample">
                                {faq.data?.map((item, i) => {
                                    return <div className="accordion-item" key={i} >
                                        <h2 className="accordion-header" id={`headingOne${i}`}>
                                            <button className={`accordion-button ${i === 0 ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${i}`} aria-expanded="true" aria-controls={`collapse${i}`}>
                                                <p>{Parser().parse(item.question)}</p>
                                            </button>
                                        </h2>
                                        <div id={`collapse${i}`} className={`accordion-collapse collapse ${i === 0 ? 'show' : ''}`} aria-labelledby={`headingOne${i}`} data-bs-parent="#accordionExample">
                                            <div className="accordion-body border-bottom bg-light">
                                                <span>{Parser().parse(item.answer)}</span>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}
