import { BrowserRouter, Route, Routes } from "react-router-dom";
import Rootlayout from "./Rootlayout";
import Home from "./components/Home";
import About from "./components/About";
import ErrorPage from "./error-page";
import Contactus from "./components/Contactus";
import MainInnerPage from "./components/MainInnerPage";
import Trekking from "./components/Trekking";
import Gallery from "./components/Gallery";
import PeakClimbing from "./components/PeakClimbing";
import ScrollToTop from "./components/ScrollToTop";
import GalleryListing from "./components/GalleryListing";
import Terms from "./components/Terms";
import Cookies from "./components/Cookies";
import Faq from "./components/Faq";
// import Blog from "./components/Blog";
// import Careers from "./components/Careers";
import Porters from "./components/Porters";
import Guides from "./components/Guides";
import Privacy from "./components/Privacy";
import Support from "./components/Support";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route element={<Rootlayout />}>
            <Route
              exact
              path='/'
              element={<Home />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/about-us'
              element={<About />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/contact-us'
              element={<Contactus />}
              errorElement={<ErrorPage />}
            />
            {/* <Route
              path='/blogs'
              element={<Blog />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/careers'
              element={<Careers />}
              errorElement={<ErrorPage />}
            /> */}
            <Route path='/faq' element={<Faq />} errorElement={<ErrorPage />} />
            <Route
              path='/guides'
              element={<Guides />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/porters'
              element={<Porters />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/supports'
              element={<Support />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/privacy-policy'
              element={<Privacy />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/terms-and-condition'
              element={<Terms />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/cookies'
              element={<Cookies />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/trekking'
              element={<Trekking />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/peak-climbing'
              element={<PeakClimbing />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/gallery'
              element={<Gallery />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='/imagegalleries/:id'
              element={<GalleryListing />}
              errorElement={<ErrorPage />}
            />
            <Route
              path='details/:slug'
              element={<MainInnerPage />}
              errorElement={<ErrorPage />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Rootlayout from "./Rootlayout";
// import NoteState from "./context/notes/NoteState";
// import Home from "./components/Home";
// import ErrorPage from "./error-page";
// import About from "./components/About";

// function App() {
//   return (
//     <>
//       <NoteState>
//         <BrowserRouter>
//           <Routes>
//             <Route element={<Rootlayout />}>
//               <Route
//                 exact
//                 path='/'
//                 element={<Home />}
//                 errorElement={<ErrorPage />}
//               />
//               <Route
//                 path='/about'
//                 element={<About />}
//                 errorElement={<ErrorPage />}
//               />
//             </Route>
//           </Routes>
//         </BrowserRouter>
//       </NoteState>
//     </>
//   );
// }

// export default App;
